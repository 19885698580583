import React from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import window from "global/window";

function SummerKindnessSeriesWeek8() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "courage_500x387" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "craftandcreate" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "research" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 8"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 8",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Show Courage"
        headerSub="Kindness Habit 8:"
        headerText={
          <>
            Stand up, speak out
            <br />
            and help others
          </>
        }
        headerImage={headerImage}
        whatisIt="Up isn’t just a direction or a delightful, animated film. Up is an action. You lift UP the spirits of someone feeling down. You stand UP for someone when they need it. In other words: you act like an upstander, not a bystander. You can encourage more UP actions by helping kids learn about moral courage, which makes them more likely to act on their empathetic urges and help others. Sounds like an UPstanding skill indeed :)"
        whyIsItImportant="When kids stand up for others, they benefit as much as the person they’re helping. They’re more likely to stick to their beliefs against peer pressure and steer away from situations that counter their family values. And they’ll likely be happier too, because showing courage boosts resilience, confidence, and willpower."
        habbits={[
          {
            header: "Highlight Heroes:",
            text: "Tell kids about the altruistic leaders you admire, and show them how they can be heroes, too!",
          },
          {
            header: "Positive Self Talk:",
            text: "Even Navy SEALS learn this habit! Create a memorable phrase kids can repeat to themselves when they need it, like “I can do this” or “I think I can.”",
          },
          {
            header: "Small-Scale Courage:",
            text: "Kids learn courage by practicing in small steps. For any situation or concern where fear, worry or anxiety take over, encourage and celebrate the tiniest risk outside of what’s comfortable.",
          },
          {
            header: "Expect Social Responsibility:",
            text: "Kids are more likely to help others if it’s a family expectation. Help make it one by making it clear that you are a family of helpers!",
          },
        ]}
        activitiesText="Practice Showing Courage at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Chain of Courage",
            text: "Connect acts of bravery!",
            onClick: () => {
              window.location.href = "https://static.classdojo.com/img/2018-summer_kindness/chain_of_courageV2.pdf";
            },
          },
          {
            icon: activity2,
            header: "Heroes Are Everywhere",
            text: "(you just have to look for them)",
            onClick: () => {
              window.location.href =
                "https://static.classdojo.com/img/2018-summer_kindness/heroes_are_everywhereV2.pdf";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek8;
